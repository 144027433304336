import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { connect } from "react-redux";
import { adminName } from "./utils/utils.js";
import Index from "./index/Index.js";

// Lazily import your pages
const AgentsPreview = lazy(() => import("./pages/Agents/AgentsPreview.js"));
const AgentDetail = lazy(() => import("./pages/Agents/AgentDetail.js"));
const SecurityEventIndex = lazy(() => import("./pages/Modules/Security events/SecurityEventIndex.js"));
const CasesList = lazy(() => import("./pages/Cases/CasesList.js"));
const CaseDetail = lazy(() => import("./pages/Cases/CaseDetail.js"));
const TasksList = lazy(() => import("./pages/Tasks/TasksList.js"));
const AlertsList = lazy(() => import("./pages/Alerts/AlertsList.js"));
const Organisations = lazy(() => import("./pages/administration/Organizations/Organisations.js"));
const OrganizationTabs = lazy(() => import("./pages/administration/Organizations/OrganizationTabs.js"));
const Settings = lazy(() => import("./pages/administration/Settings.js"));
const Profiles = lazy(() => import("./pages/administration/Profiles.js"));
const AnalysisReportTable = lazy(() => import("./pages/Reports/AnalysisReportTable.js"));
const Dashboard = lazy(() => import("./pages/Dashboards/Dashboard.js"));
const ViewXmlPage = lazy(() => import("./ViewXmlPage .js"));

const Directions = (props) => {
  return (
    <>
      <CssBaseline />
      <Index>
        {/* Suspense component to display fallback UI while components load */}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/view-xml" element={<ViewXmlPage />} />
            {props.user.profile !== adminName && (
              <>
                <Route path="/alerts" element={<AlertsList />} />
                <Route path="/tasks" element={<TasksList />} />
                <Route path="/cases" element={<CasesList />} />
                <Route path="/case-detail/:caseId" element={<CaseDetail />} />
                <Route path="/agents" element={<AgentsPreview />} />
                <Route path="/agents-preview" element={<AgentDetail />} />
                <Route path="/modules-events" element={<SecurityEventIndex />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/reports" element={<AnalysisReportTable />} />
              </>
            )}
            {props.dashboards && (
              <Route path="/dashboards/:tier" element={<Dashboard />} />
            )}
            {props.permissions.mPrf && (
              <Route path="/profiles" element={<Profiles />} />
            )}
            {props.permissions.mUsers && (
              <Route path="/organizations/:orgName" element={<OrganizationTabs />} />
            )}
            {props.permissions.mOrg && (
              <Route path="/organizations" element={<Organisations />} />
            )}
            {/* administration */}
            {props.user.profile === adminName && (
              <>
                <Route path="/settings" element={<Settings />} />
                <Route path="/organizations/:orgName" element={<OrganizationTabs />} />
              </>
            )}
            {props.user.profile === adminName && props.permissions.mOrg ? (
              <Route path="/" element={<Organisations />} />
            ) : (
              <Route path="/" element={<CasesList />} />
            )}
          </Routes>
        </Suspense>
      </Index>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.cms_user,
    permissions: state.permissions,
    dashboards: state.dashboards,
  };
};

export default connect(mapStateToProps, null)(Directions);
